function zeroPadding(num: number, length: number): string{
    if (String(num).length > length) throw new Error("Invalid parameter. The number of digits in num must be less than or equal to length.")
    return ((Array(length).fill(0)).join("") + num).slice(String(num).length)
}
export function format(date: Date, formatStr = "%Y/%m/%d %H:%M") {
    formatStr = formatStr.replace(/%Y/g, String(date.getFullYear()))
    formatStr = formatStr.replace(/%m/g, zeroPadding(date.getMonth()+1, 2))
    formatStr = formatStr.replace(/%d/g, zeroPadding(date.getDate(), 2))
    formatStr = formatStr.replace(/%H/g, zeroPadding(date.getHours(), 2))
    formatStr = formatStr.replace(/%M/g, zeroPadding(date.getMinutes(), 2))
    return formatStr
}
export function shiftMonth(baseDate: Date, shiftDiff: number): Date {
    const newMonth = baseDate.getMonth() + shiftDiff
    const shiftYear = Math.floor(newMonth / 12)
    const month = newMonth % 12
    baseDate.setFullYear(baseDate.getFullYear() + shiftYear, month)
    return baseDate
}
