import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import {profile} from "@/store/User";
import createPersistedState from "vuex-persistedstate"
import Vue from "vue";

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
    state: {
        version: '1.0.0',
    },
    modules: {
        profile: profile,
    },
    strict: true,
    plugins: [
        createPersistedState({
            key: "WorkCalendar",
            paths: ["profile"],
            storage: window.localStorage
        })
    ]
};

export default new Vuex.Store<RootState>(store);
