import CalendarEvent from "@/utils/calendarEvent";
import {API_URL} from "@/settings";
import axios from "axios";
import {AxiosResponse} from "axios"

import {format} from "@/utils/date";
import {UserInfoModel} from "@/utils/models";

const _LOGIN_URL = `${API_URL}/api/v1/auth/login`
const _REGISTER_URL = `${API_URL}/api/v1/auth/register`
const _API_KEY_GET = `${API_URL}/api/v1/auth/api-key/`      // get
const _API_KEY_CREATE = `${API_URL}/api/v1/auth/api-key/`   // post
const _API_KEY_DELETE = `${API_URL}/api/v1/auth/api-key/`   // delete

const _USER_INFO_URL = `${API_URL}/api/v1/user/detail`

const _GET_URL = `${API_URL}/api/v1/event/`
const _POST_URL = `${API_URL}/api/v1/event/`
const _PUT_URL = `${API_URL}/api/v1/event/%uuid/`
const _PATCH_URL = `${API_URL}/api/v1/event/%uuid/`
const _DELETE_URL = `${API_URL}/api/v1/event/%uuid/`


export class EventActions {
    private _token: string
    private _expire: Date
    private readonly _AUTHED_CONFIG: { [key: string]: any }

    constructor(token: string, expire: Date) {
        this._token = token
        this._expire = expire
        this._AUTHED_CONFIG = {
            headers: {
                Authorization: `Token ${token}`
            }
        }
    }

    getList(start: string | null, period: string, success: (response: AxiosResponse) => void, fail?: (response: AxiosResponse) => void) {
        const config: { [key: string]: any } = {};
        config.params = {}
        Object.assign(config, this._AUTHED_CONFIG)

        start ? config["params"]["start"] = start : null
        config["params"]["period"] = period


        axios.get(
            _GET_URL,
            config
        ).then(response => {
            success(response)
        }).catch(reason => {
            if (fail) {
                fail(reason.response)
            } else {
                throw reason
            }
        })
    }

    create(event: CalendarEvent, success: (response: AxiosResponse, event: CalendarEvent) => void, fail?: (response: AxiosResponse, event: CalendarEvent) => void) {
        const params = new URLSearchParams({
            title: event.name,
            start: format(event.start, "%Y-%m-%dT%H:%M"),
            end: format(event.end, "%Y-%m-%dT%H:%M"),
            summary: event.summary,
            color: event.color,
            location: event.location,
            timed: event.timed ? "1" : "0"
        })
        axios.post(
            _POST_URL,
            params,
            this._AUTHED_CONFIG
        ).then((response) => {
            event.id = response.data.id
            success(response, event)
        }).catch((reason => {
                if (fail) {
                    fail(reason.response, event)
                } else {
                    console.error(reason.response.data)
                }
            })
        )
    }

    update(event: CalendarEvent, success: (response: AxiosResponse, event: CalendarEvent) => void, fail?: (response: AxiosResponse, event: CalendarEvent) => void) {
        if (event.id === null) throw new Error("Event id is null")
        const params = new URLSearchParams({
            title: event.name,
            start: format(event.start, "%Y-%m-%dT%H:%M"),
            end: format(event.end, "%Y-%m-%dT%H:%M"),
            summary: event.summary,
            color: event.color,
            location: event.location,
            timed: event.timed ? "1" : "0"
        })
        axios.put(
            _PUT_URL.replace("%uuid", event.id),
            params,
            this._AUTHED_CONFIG
        ).then((response) => {
            success(response, event)
        }).catch(reason => {
            if (fail) {
                fail(reason.response, event)
            }
        })
    }

    partialUpdate(event: CalendarEvent, updateParams: Record<string, any>, success: (response: AxiosResponse, event: CalendarEvent) => void, fail?: (response: AxiosResponse, event: CalendarEvent) => void) {
        if (event.id === null) throw new Error("Event id is null")
        const params = new URLSearchParams()
        for (const key in updateParams) {
            if (!Object.prototype.hasOwnProperty.call(updateParams, key)) {
                break
            }
            if (typeof updateParams[key] === "boolean") updateParams[key] = updateParams[key] ? "1" : "0"
            else if (updateParams[key] instanceof Date) {
                updateParams[key] = format(updateParams[key], "%Y-%m-%dT%H:%M")
            }
            params.append(key, updateParams[key])
        }
        axios.patch(
            _PATCH_URL.replace("%uuid", event.id),
            params,
            this._AUTHED_CONFIG
        ).then((response) => {
            success(response, event)
        }).catch(reason => {
            if (fail) {
                fail(reason.response, event)
            }
        })
    }

    destroy(event: CalendarEvent, success: (response: AxiosResponse, event: CalendarEvent) => void, fail?: (response: AxiosResponse, event: CalendarEvent) => void) {
        if (event.id === null) throw new Error("Event id is null.")
        axios.delete(
            _DELETE_URL.replace("%uuid", event.id),
            this._AUTHED_CONFIG
        ).then(response => {
            success(response, event)
        }).catch(reason => {
            if (fail) {
                fail(reason.response, event)
            }
        })
    }
}

export class AccountActions {
    private _token: string
    private _expire: Date
    private _AUTHED_CONFIG: { [key: string]: any }

    constructor(token: string, expire: Date) {
        this._token = token
        this._expire = expire
        this._AUTHED_CONFIG = {
            headers: {
                Authorization: `Token ${token}`
            }
        }
    }

    login(email: string, password: string, success: (response: AxiosResponse, token: string, expire: string) => void, fail?: (response: AxiosResponse) => void) {
        const params = new URLSearchParams({
            email: email,
            password: password
        })
        axios.post(
            _LOGIN_URL,
            params,
        ).then(response => {
            const token = response.data.key
            const expire = response.data.expire
            success(response, token, expire)
        }).catch(reason => {
            if (fail) {
                fail(reason.response)
            }
        })
    }

    getAccountDetail(success: (response: AxiosResponse, userInfo: UserInfoModel) => void, fail?: (response: AxiosResponse) => void): void {
        axios.get(
            _USER_INFO_URL,
            this._AUTHED_CONFIG
        ).then(response => {
            const userInfo: UserInfoModel = {
                nickname: response.data.nickname,
                firstName: response.data.first_name,
                lastName: response.data.last_name,
                email: response.data.email,
                id: response.data.id
            }
            success(response, userInfo)
        }).catch(reason => {
            if (fail) {
                fail(reason.response)
            } else {
                throw reason
            }
        })
    }

    getApiKey(success: (response: AxiosResponse, key: string) => void, fail?: (response: AxiosResponse) => void): void {
        axios.get(
            _API_KEY_GET,
            this._AUTHED_CONFIG
        ).then(response => {
                const key = response.data.key
                success(response, key)
            }
        ).catch(reason => {
            console.log(this._AUTHED_CONFIG)
            if (fail) {
                fail(reason.response)
            } else {
                throw reason
            }
        })
    }

    generateApiKey(success: (response: AxiosResponse, key: string) => void, fail?: (response: AxiosResponse) => void): void {
        axios.post(
            _API_KEY_CREATE,
            {},
            this._AUTHED_CONFIG
        ).then(response => {
                const key = response.data.key
                success(response, key)
            }
        ).catch(reason => {
            if (fail) {
                fail(reason.response)
            } else {
                throw reason
            }
        })
    }

    removeApiKey(success: (response: AxiosResponse) => void, fail?: (response: AxiosResponse) => void): void {
        axios.delete(
            _API_KEY_CREATE,
            this._AUTHED_CONFIG
        ).then(response => {
                success(response)
            }
        ).catch(reason => {
            if (fail) {
                fail(reason.response)
            } else {
                throw reason
            }
        })
    }

    // APIKey = {
    //     get: this.getApiKey,
    //     generate: this.generateApiKey,
    //     remove: this.removeApiKey
    // }
}
