<template>
  <v-container>
    <v-sheet>
      <h1>Top page</h1>
      <router-link to="calendar">Calendar</router-link>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
name: "TopPage"
}
</script>

<style scoped>

</style>
