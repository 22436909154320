































import Vue from 'vue';
import Account from "@/components/AccountComponents/Account.vue"

export default Vue.extend({
  name: 'App',

  components: {
    Account
  },

  data: () => ({
    drawer: false
  }),
});
