






































import Vue from "vue";
import Vuex from "vuex";
import {ColorTheme} from "@/settings";
import {AccountActions} from "@/utils/http";
import {UserInfoModel} from "@/utils/models";

declare interface DataType {
  loading: boolean;
  colorTheme: Record<string, string>;
  userInfo: UserInfoModel;
  apiKey: string;
  showApiKey: boolean;
  accountActions: AccountActions;
}

Vue.use(Vuex)

export default Vue.extend({
  name: "Account",
  data(): DataType {
    return {
      loading: true,
      colorTheme: ColorTheme,
      userInfo: {id: "", nickname: "", firstName: "", lastName: "", email: ""},
      apiKey: "",
      showApiKey: false,
      accountActions: new AccountActions(this.$store.getters["profile/getToken"], this.$store.getters["profile/getExpireDate"])
    }
  },
  methods: {
    handleApiShowToggle() {
      this.showApiKey = !this.showApiKey
      if (this.showApiKey) {
        this.accountActions.getApiKey(
            (response, key) => {
              this.apiKey = key
            }
        )
      }
    },
    handleRemoveApi() {
      this.accountActions.removeApiKey(
          response => {
            this.apiKey = ""
          }
      )
    },
    handleReGenApi() {
      this.accountActions.generateApiKey(
          (response, key) => {
            this.apiKey = key
          }
      )
    }
  },
  mounted() {
    this.accountActions.getAccountDetail(
        (response, userInfo) => {
          this.userInfo = userInfo
          this.loading = false
        }
    )
  }
})
