import {Module} from "vuex";
import {RootState, ProfileState, Authorization} from "@/store/types";
import getters from "@/store/User/getters";
import mutations from "@/store/User/mutations";
import actions from "@/store/User/actions";

const auth: Authorization = {
    token: null,
    expire: null,
    loggedIn: false
}

const state: ProfileState = {
    id: "",
    email: "",
    nickname: "Guest",
    firstName: "",
    lastName: "",
    authorization: auth
}

export const profile: Module<ProfileState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
