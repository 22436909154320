






import { Component, Vue } from 'vue-property-decorator';
import TopPage from "@/components/TopPage.vue"

@Component({
  name: "Home",
  components: {
    TopPage,
  },
})

export default class Home extends Vue {}
