import {GetterTree} from "vuex";
import {RootState, ProfileState} from "@/store/types";

const getters: GetterTree<ProfileState, RootState> = {
    getToken(state): string | null {
        return state.authorization.token
    },
    getExpireDate(state): Date | null {
        if (state.authorization.expire === null) return null
        return new Date(Date.parse(state.authorization.expire))
    },
    getLoggedIn(state): boolean {
        return state.authorization.loggedIn
    },
    isExpiredToken(state): boolean {
        if (state.authorization.expire === null) return false
        const now = new Date()
        const expire = new Date(Date.parse(state.authorization.expire))
        return now > expire
    },
}

export default getters
