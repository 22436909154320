import {MutationTree} from 'vuex';
import {ProfileState} from "@/store/types";

const mutations: MutationTree<ProfileState> = {
    setLoginInfo: (state, auth: Record<string, string>) => {
        state.authorization.token = auth.key
        state.authorization.expire = auth.expire
        state.authorization.loggedIn = true
    },
    setAccountDetail: (state, user: Record<string, string>) => {
        state.id = user.id
        state.email = user.email
        state.nickname = user.nickname
        state.firstName = user.firstName
        state.lastName = user.lastName
    },
    setLoggedIn: (state, bool: boolean) => {
        state.authorization.loggedIn = bool
    }
}
export default mutations
